import CustomCard, { CustomListItem } from '../../../../shared/Cards/CustomCard';
import ProfileDetail from '../ProfileDetail';
import CustomTable from '../../../../shared/Table/CustomTable';
import { useEffect, useState } from 'react';
import { getAgreements } from '../../../../redux/actions/MembersPortal/memberPortalActions';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { CustomCheckbox, CustomInput } from '../../../../shared/Input/AllInputs';
import AgreementView from './AgreementView';
import { dateConversions } from '../../../../utils/commonFunctions';
import { FirstletterUpperCase } from '../../../../utils/javascript';

const Agreement = ({ data = {} }) => {
    const dispatch = useDispatch();
    const { id } = useParams();
    const agreement = useSelector((state) => state.membersPortal.agreement);
    const [openModal, setOpenModal] = useState(false);
    const [itemData, setItemData] = useState(null);
    const history = useHistory();

    const columns = [
        { field: 'membershipPlan', header: 'Agreement Name' },
        { field: 'agreementNo', header: 'Agreement #' },
        { field: 'createdAt', header: 'Created Date', body: (r) => dateConversions(r?.createdAt), style: { width: '150px' } },
        { field: 'signDate', header: 'Signed Date', body: (r) => dateConversions(r?.signDate) },
        {
            field: 'agreementTerm',
            header: 'Agreement Term',
            body: (r) => {
                const { Term } = renewalFrequency(r?.timePeriod, r?.howOftenWillClientsBeCharged, r?.noOfAutopays);
                return Term;
            },
        },
        {
            field: 'renewalFrequency',
            header: 'Renewal Frequency',
            body: (r) => {
                const { frequency } = renewalFrequency(r?.timePeriod, r?.howOftenWillClientsBeCharged, r?.noOfAutopays);
                return frequency;
            },
        },
        {
            field: 'renewalType',
            header: 'Renewal Type',
            body: (r) => {
                const { Type } = renewalFrequency(r?.timePeriod, r?.howOftenWillClientsBeCharged, r?.noOfAutopays);
                return Type;
            },
        },
        { field: 'billedAmount', header: 'Billed Amount', body: () => '-' },
        { field: 'salesPerson', header: 'Salesperson' },
        { field: 'status', header: 'Status', body: (value) => (value ? 'Active' : 'Inactive') },
    ];

    useEffect(() => {
        dispatch(getAgreements(id));
    }, [dispatch, id]);

    const renewalFrequency = (timePeriod, type, noOfAutopays) => {
        let frequency = `Every ${timePeriod} month`;
        let Type = `${
            type &&
            type
                ?.toLowerCase()
                .replace(/_/g, ' ')
                .replace(/\b\w/g, (char) => char.toUpperCase())
        }`;
        let Term = timePeriod === 1 ? 'Until Cancel' : timePeriod * noOfAutopays;
        return { frequency, Type, Term };
    };

    const { frequency, Type, Term } = renewalFrequency(agreement?.[0]?.timePeriod, agreement?.[0]?.howOftenWillClientsBeCharged, agreement?.[0]?.noOfAutopays);

    console.log(frequency, Type, Term, 'Frequency');

    const customActionTemplate = (r) => {
        return (
            <>
                <i
                    className="mx-2 cursor-pointer pi pi-eye"
                    onClick={() => {
                        setOpenModal(true);
                        setItemData(data);
                    }}
                />
                <i
                    className="mx-2 cursor-pointer pi pi-file-o"
                    onClick={() => {
                        history.replace(`/members/subscription-agreement/${r.MemberSubscriptionAgreementTemplate?.subscription}`);
                    }}
                />
            </>
        );
    };

    return (
        <div>
            <ProfileDetail />
            <div className="grid">
                <CustomCard
                    title="Misc"
                    col={6}
                    name="Edit"
                    // onClick={() => {
                    //     setVisiblePersonal(id);
                    // }}
                    height="250px"
                >
                    <CustomListItem name="Campaign" data={agreement?.[0]?.campaign} />
                    <CustomListItem name="referredBy" data={data} />
                    <CustomListItem name="referrals" data={data} />
                </CustomCard>
                <CustomCard
                    title="Associations"
                    col={6}
                    name="Edit"
                    // onClick={() => {
                    //     setVisiblePersonal(id);
                    // }}
                    height="250px"
                >
                    <div className="flex justify-content-between">
                        <div className="flex gap-3">
                            <CustomCheckbox col={5} data={data} label="family" name="family" />
                            <CustomCheckbox col={8} data={data} label="Corporate" name="corporate" />
                        </div>
                        <div className="flex align-items-center justify-content-end gap-3">
                            <span>Primary Account</span>
                            <CustomInput name="" />
                        </div>
                    </div>
                </CustomCard>
                <CustomCard
                    title="Renewal"
                    col={6}
                    name="Edit"
                    // onClick={() => {
                    //     setVisiblePersonal(id);
                    // }}
                    height="250px"
                >
                    <CustomListItem name="Frequency" value={frequency} />
                    <CustomListItem name="Type" value={Type} />
                    <CustomListItem name="Term" value={Term} />
                </CustomCard>
                <CustomCard title="Relationships" col={6} name="Edit" onClick={() => {}} height="250px">
                    <CustomListItem label="Pays for" data={data} />
                    <CustomListItem label="Pays by" data={data} />
                    <CustomListItem label="Shares w/" data={data} />
                </CustomCard>
            </div>
            <CustomTable data={agreement} columns={columns} customActionTemplate={customActionTemplate} />
            <AgreementView itemData={itemData} setItemData={setItemData} openModal={openModal} setOpenModal={setOpenModal} />
        </div>
    );
};

export default Agreement;
