import { useEffect, useState } from 'react';

/**
 * Custom hook for debouncing a value.
 * @param {any} value - The value to debounce.
 * @param {number} delay - The debounce delay in seconds.
 * @returns {any} - The debounced value.
 */
const useDebounce = (value, delay = 1) => {
    delay = delay * 1000;
    const [debouncedValue, setDebouncedValue] = useState(value);

    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedValue(value);
        }, delay);

        return () => {
            clearTimeout(handler); // Cleanup timeout on value change or unmount
        };
    }, [value, delay]);

    return debouncedValue;
};

export default useDebounce;
