import React from 'react';
import Events from '../../assets/icons/Events.png';
import CustomTransition from '../../shared/Transitions/CustomTransition';
import Calendar from '../../assets/icons/morecal.png';
import EmployeeAvailability from '../../assets/icons/Employee.png';
import Substitutions from '../../assets/icons/substitutions.png';
import RepeatingEvents from '../../assets/icons/RepeatingEvents.png';

import GridNavigation from '../../shared/GridNavigation/GridNavigation';

export default function MoreSchedule() {
    const items = [
        {
            img: Calendar,
            link: '/calender',
            title: 'Calendar',
        },
        {
            img: Events,
            link: '/settings/schedule?tab=events-setups',
            title: 'Events',
        },
        {
            img: EmployeeAvailability,
            link: '/more/members/reserve',
            title: 'Employee Availability',
        },
        {
            img: Substitutions,
            link: '/more/members/tasks',
            title: 'Substitutions',
        },
        {
            img: RepeatingEvents,
            link: '/calender/events/67c5969386133fffc702d41c/repeat-event',
            title: 'Repeating Events',
        },
    ];
    return (
        <CustomTransition>
            <GridNavigation items={items} backable />
        </CustomTransition>
    );
}
