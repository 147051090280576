import React, { useEffect, useRef, useState } from 'react';
import { CustomDropDown } from '../../shared/Input/AllInputs';
import PrimaryButton from '../../shared/Button/CustomButton';
import { useHistory } from 'react-router-dom';
import { Menu } from 'primereact/menu';
import { calendarCustomViewOptions, calendarViewOptions } from '../../utils/dropdownConstants';
import { formatDate, formatDateRange } from './calendarHelper';
import calendarIcon from '../../assets/icons/calendar.png';
import CustomDialog from '../../shared/Overlays/CustomDialog';
import { useDispatch, useSelector } from 'react-redux';
import { getCalendarAvailability, getCalendarRecentSessions } from '../../redux/actions/Calendar/CalendarAction';
import CustomTable from '../../shared/Table/CustomTable';
import { getDateandTime } from '../../utils/commonFunctions';
import useEmployees from '../../hooks/Employees/useEmployees';

const CalendarHeader = ({
    calendarRef,
    showDatePicker,
    setShowDatePicker,
    calendarDate,
    setCalendarDate,
    calendarView,
    setCalendarView,
    setSelectedResource,
    selectedResource,
    view,
    setView,
    updateCalendarDate,
}) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const menu = useRef(null);

    const [openAvailabilty, setOpenAvailability] = useState(false);
    const [openRecentSession, setOpenRecentSession] = useState(false);

    useEffect(() => {
        handleToday();
    }, []);

    useEffect(() => {
        dispatch(getCalendarAvailability());
        dispatch(getCalendarRecentSessions());
    }, []);
    const handleCalendarChange = ({ name, value }) => {
        const calendarAPI = calendarRef?.current?.getApi();

        if (calendarAPI) {
            let newView;

            if (name === 'view') {
                if (value === 'day') {
                    newView = calendarView === 'calendar view' ? 'timeGridDay' : 'resourceTimeGridDay';
                } else if (value === 'week') {
                    newView = calendarView !== 'calendar view' ? 'resourceTimeGridWeek' : 'timeGridWeek';
                } else {
                    newView = null;
                }
                setView(value);
            } else if (name === 'calendarView') {
                if (view === 'day') {
                    newView = value === 'calendar view' ? 'timeGridDay' : 'resourceTimeGridDay';
                } else if (view === 'week') {
                    newView = value !== 'calendar view' ? 'resourceTimeGridWeek' : 'timeGridWeek';
                } else {
                    newView = null;
                }
                setSelectedResource(null);

                setCalendarView(value);
            }

            if (newView) {
                calendarAPI.changeView(newView);
                updateCalendarDate(calendarAPI.view);
            }
        }
    };

    const handleResourceChange = ({ name, value }) => {
        setSelectedResource(value);
    };

    let CalendarItems = [
        { label: 'Recent Sessions', command: () => setOpenRecentSession(true) },
        { label: 'Availability', command: () => setOpenAvailability(true) },
    ];

    const handleNavigation = (action) => {
        const calendarApi = calendarRef?.current?.getApi();
        if (!calendarApi) return;

        if (action === 'today') {
            calendarApi.gotoDate(new Date());
            updateCalendarDate(calendarApi.view);
        } else if (action === 'prev') {
            calendarApi.prev();
        } else if (action === 'next') {
            calendarApi.next();
        }

        updateCalendarDate(calendarApi.view);
    };
    const handleToday = () => handleNavigation('today');
    const handlePrev = () => handleNavigation('prev');
    const handleNext = () => handleNavigation('next');
    const onClose = () => {
        setOpenAvailability(false);
    };
    const columns = [
        {
            field: 'eventDate',
            body: (r) => getDateandTime(r?.eventDate),
            header: ' Date',
        },
        { field: 'event.name', body: (r) => r?.event?.name, header: 'Event' },
        { field: 'staff', header: 'Employee' },
        { field: 'resources', body: (r) => r?.resources[0]?.name, header: 'Resources' },
        {
            field: 'isStaffVerified',
            body: (r) =>
                r?.isStaffVerified ? (
                    <i className="mx-2 cursor-pointer pi pi-check-circle text-green-500"></i>
                ) : (
                    <i className="mx-2 cursor-pointer pi pi-times-circle text-red-500"></i>
                ),

            header: '',
        },
    ];
    const columns1 = [
        {
            field: 'createdAt',
            body: (r) => getDateandTime(r?.createdAt),
            header: ' Date',
        },
        { field: 'event', header: 'Event' },
        { field: 'staff', header: 'Employee' },
        { field: 'resources', header: 'Resources' },
    ];
    const { availability, recentSessions } = useSelector((state) => state.calendar);
    const resetView = () => {
        setSelectedResource(null);
        const calendarApi = calendarRef.current?.getApi();
        if (calendarApi) {
            calendarApi.changeView('resourceTimeGridDay'); // Switch back to full view
        }
    };
    const { employeesDropdown } = useEmployees();
    const { calendarResourcesDropdown, calendarLocationDropdown } = useSelector((state) => state.calendar);

    const dropDownOptions =
        calendarView === 'employee'
            ? employeesDropdown
            : calendarView === 'resources'
              ? calendarResourcesDropdown
              : calendarView === 'location'
                ? calendarLocationDropdown
                : [];

    return (
        <div className="w-full flex justify-content-between surface-ground mb-2 border-round-md">
            <div className="flex">
                <PrimaryButton onClick={handleToday} size="small" className="mx-2 my-auto">
                    Today
                </PrimaryButton>
                <PrimaryButton icon="pi pi-angle-left" onClick={handlePrev} size="small" />
                <PrimaryButton icon="pi pi-angle-right" onClick={handleNext} size="small" />
                {/* <div className="my-auto font-bold mx-2">{calendarDate}</div> */}
                <div className="my-auto font-bold mx-2 cursor-pointer ">{calendarDate instanceof Date ? calendarDate.toLocaleDateString() : calendarDate}</div>
                <img
                    src={calendarIcon}
                    alt="cal1"
                    style={{ width: '30px', height: '30px' }}
                    className="my-auto cursor-pointer"
                    onClick={() => setShowDatePicker(!showDatePicker)}
                />
            </div>

            <div className="flex justify-content-end col-7 align-items-center">
                <CustomDropDown
                    name="calendarView"
                    col={3}
                    options={calendarCustomViewOptions}
                    showLabel={false}
                    onChange={handleCalendarChange}
                    value={calendarView}
                    extraClassName=""
                    // inputClass="h-2rem"
                />
                {selectedResource && (
                    <CustomDropDown
                        name="selected"
                        col={3}
                        options={dropDownOptions}
                        showLabel={false}
                        onChange={handleResourceChange}
                        value={selectedResource}
                        extraClassName=""
                    />
                )}
                <CustomDropDown
                    name="view"
                    col={3}
                    options={calendarViewOptions}
                    showLabel={false}
                    onChange={handleCalendarChange}
                    value={view}
                    extraClassName=""
                />
                {/* <PrimaryButton className="mx-2" onClick={(e) => menu.current.toggle(e)} size="small">
                    Options
                </PrimaryButton> */}
                <PrimaryButton icon="pi pi-plus-circle " className="mx-2 p-2" size="small" onClick={() => history.push('/settings/schedule/classes/add')}>
                    Add Class
                </PrimaryButton>
                {selectedResource && (
                    <PrimaryButton onClick={resetView} className="mx-2" size="small">
                        Reset
                    </PrimaryButton>
                )}

                <Menu model={CalendarItems} popup ref={menu} />
                <CustomDialog loading={false} title="Availability" visible={openAvailabilty} onCancel={() => setOpenAvailability(false)} width="100vh">
                    <CustomTable data={availability} columns={columns} convertToboolean={false} />
                </CustomDialog>
                <CustomDialog loading={false} title="Recent Sessions" visible={openRecentSession} onCancel={() => setOpenRecentSession(false)} width="100vh">
                    <CustomTable data={recentSessions} columns={columns1} convertToboolean={false} />
                </CustomDialog>
            </div>
        </div>
    );
};

export default CalendarHeader;
