const BillingCard = ({ item, className, onClick = () => {} }) => {
    return (
        <div
            className={`saved-cards ${className}`}
            // onClick={() => onSelectCard(item.customerPaymentProfileId)}
            onClick={onClick}
        >
            <div className="card-details">
                <span>
                    <span>
                        <strong>{item?.payment?.creditCard?.cardNumber}</strong>
                    </span>
                    <strong>
                        {item?.billTo?.firstName} {item?.billTo?.lastName}
                    </strong>
                </span>
            </div>
            <div className="card-type">{item?.payment?.creditCard?.cardType}</div>
        </div>
    );
};

export default BillingCard;
