import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { createMemberSubscription, getActivePlan, getMemberDetails } from '../../../redux/actions/Plans/plansActions';
import { getMembers } from '../../../redux/actions/MembersPortal/memberPortalActions';
import { getDueDate, getFirstDueDate } from '../../../utils/dateTime';
import useCancelSellPlans from '../../../hooks/useCancelSellPlans';
import { calculateFinalAmount } from '../../../utils/taxHelpers';
import CustomTabView from '../../../shared/TabView/CustomTabView';
import FormPage from '../../../shared/Layout/FormPage';

import { getMembersipTypes } from '../../../redux/actions/Settings/MembershipSetup/membershipTypeAction';
import { getCampaigns } from '../../../redux/actions/Settings/MembershipSetup/campaignsAction';
import { getEmployees } from '../../../redux/actions/Settings/Employee/employeesAction';

import PlanTab from './PlanTab';
import PersonalTab from './PersonalTab';
import AgreementTab from './AgreementTab';
import PaymentAmountTab from './PaymentAmountTab';
import BillingInfoTab from './BillingInfoTab';
import useValidateBarcode from '../../../hooks/Members/useValidateBarcode';
import useValidateEmail from '../../../hooks/Members/useValidateEmail';
import { showFormErrors } from '../../../utils/commonFunctions';

const SellPlanForm = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const { newPlanId, id } = useParams();
    useEffect(() => {
        dispatch(getEmployees());
        dispatch(getCampaigns());
        dispatch(getMembersipTypes());
    }, [dispatch]);
    // we will get the member id if someone come from draft plans or if someone has refreshed any tab , so it will refetch the member details
    useEffect(() => {
        let _selectedMember = localStorage.getItem('selectedMember');
        if (_selectedMember) {
            setSelectedMember(_selectedMember);
        }
    }, []);

    const [disabledTabIndices, setDisabledTabIndices] = useState([1, 2, 3, 4]);

    // this will fetch the plan info like name category, assessedFee and the services inclued with their taxes and everything
    useEffect(() => {
        dispatch(
            getActivePlan(id, (e) => {
                let beginDate = new Date();
                setPlanInfo((prev) => ({
                    ...prev,
                    _id: e._id,
                    category: e.category,
                    club: e.club,
                    name: e.name,
                    membershipTypeName: e.membershipTypeName,
                    timePeriod: e.timePeriod,
                    interval: e.interval,
                    beginDate,

                    assessedFee: e.assessedFee.map((item) => ({
                        ...item,
                        dueDate: getDueDate({
                            paymentOption: item.dueDateDeterminedBy,
                            specificDate: item.preferredDate,
                            daysFromBeginDate: item.noOfDays,
                            beginDate,
                        }),
                        apply: true,
                    })),
                    services: [...e.services, ...e.membershipTypeServices].map((item) => ({
                        ...item,
                        // firstDueDate: getFirstDueDate(e.whenWillClientsBeCharged, e.date),new Date()
                        firstDueDate: beginDate,
                        unitPrice: calculateFinalAmount(
                            item.netPrice,
                            item?.taxes.reduce((total, tax) => total + tax.taxRatePercentage, 0),
                        ),
                    })),

                    agreementNo: e.agreementNo,
                    membershipType: e.membershipType,
                    howOftenWillClientsBeCharged: e.howOftenWillClientsBeCharged,
                    whenWillClientsBeCharged: e.whenWillClientsBeCharged,
                    date: e.date,
                }));
            }),
        );
    }, [id, dispatch]);

    useEffect(() => {
        dispatch(getMembers());
    }, [dispatch]);

    const [selectedMember, setSelectedMember] = useState(null);
    const [planInfo, setPlanInfo] = useState({
        club: '',
        category: '',
        name: '',
        membershipTypeName: '',
        timePeriod: 0,
        interval: 'months',

        assessedFee: [],
        services: [],

        agreementNo: 0,
        membershipType: '',
        howOftenWillClientsBeCharged: '',

        salesPerson: null,
        referredBy: null,
        campaign: null,

        memberSince: '',
        signDate: new Date(),
        beginDate: new Date(),
    });

    //When we chnage the begindate it will change the due date of assessed fee and services also
    useEffect(() => {
        if (planInfo.beginDate) {
            let beginDate = planInfo.beginDate;
            setPlanInfo((prev) => ({
                ...prev,
                assessedFee: prev.assessedFee.map((item) => ({
                    ...item,
                    dueDate: getDueDate({
                        paymentOption: item.dueDateDeterminedBy,
                        specificDate: item.preferredDate,
                        daysFromBeginDate: item.noOfDays,
                        beginDate,
                    }),
                })),
                services: prev.services.map((item) => ({
                    ...item,
                    firstDueDate: beginDate,
                })),
            }));
        }
    }, [planInfo.beginDate]);

    //Members info and validations
    const _memberInfoInitialState = {
        accessCode: '',
        barCode: '',

        image: [],
        firstName: '',
        lastName: '',
        gender: '',
        dob: '',

        primaryPhone: '',
        address: '',
        latitude: '',
        longitude: '',
        city: '',
        email: '',
        state: '',
        zipCode: '',

        emergencyContactName: '',
        emergencyContactNumber: '',
        customerPaymentProfiles: [],
    };
    const [memberInfo, setMemberInfo] = useState(_memberInfoInitialState);
    let { barCodeError } = useValidateBarcode(memberInfo, setMemberInfo);
    let { emailError } = useValidateEmail(memberInfo, setMemberInfo);

    useEffect(() => {
        if (selectedMember) {
            localStorage.setItem('selectedMember', selectedMember);
            dispatch(
                getMemberDetails(selectedMember, (e) => {
                    setMemberInfo({
                        _id: e._id,
                        image: e.image ? [e.image] : [],
                        accessCode: e.accessCode || '',
                        barCode: e.barCode,

                        firstName: e.firstName,
                        lastName: e.lastName,
                        gender: e.gender,
                        dob: e.dob ? new Date(e.dob) : '',
                        primaryPhone: e.primaryPhone,
                        address: e.address,
                        latitude: e.latitude,
                        longitude: e.longitude,
                        city: e.city,
                        email: e.email || '',
                        state: e.state,
                        zipCode: e.zipCode,
                        emergencyContactName: e.emergencyContactName,
                        emergencyContactNumber: e.emergencyContactNumber,
                        customerPaymentProfiles: e.customerPaymentProfiles,
                    });
                    setPlanInfo((prev) => ({ ...prev, memberSince: new Date(e.createdAt) }));
                }),
            );
        } else {
            localStorage.removeItem('selectedMember');
        }
        //eslint-disable-next-line
    }, [selectedMember, dispatch]);

    const onTabEnable = (index) => {
        setDisabledTabIndices((prev) => prev.filter((item) => item > index));
    };
    const { confirm } = useCancelSellPlans(newPlanId);

    const [payment, setPayment] = useState({
        paymentMethodType: 'CREDIT_CARD',
        enableCardOnFile: false,
        useClubAccount: true,
    });
    const [loading, setLoading] = useState(false);

    const onSubmit = (payment) => {
        if (barCodeError || emailError) {
            history.replace({
                search: `tab=personal`,
            });
            setLoading(false);
            return;
        }
        if (!showFormErrors(memberInfo, setMemberInfo)) {
            history.replace({
                search: `?tab=personal`,
            });
            setLoading(false);
            return;
        }
        if (!showFormErrors(planInfo, setPlanInfo)) {
            history.replace({
                search: `?tab=agreement`,
            });
            setLoading(false);
            return;
        }

        if (payment) {
            let payload = {
                selectedMember,
                planId: id,
                payment: payment,
                member: memberInfo,
                plan: planInfo,
            };
            dispatch(
                createMemberSubscription(payload, setLoading, (subscription) => {
                    history.push(`/plans/subscription-agreement/${subscription._id}`);
                }),
            );
        }
    };

    const tabs = [
        {
            title: 'Plan',
            content: (
                <PlanTab
                    planInfo={planInfo}
                    selectedMember={selectedMember}
                    setSelectedMember={setSelectedMember}
                    onTabEnable={onTabEnable}
                    onCancel={confirm}
                />
            ),
        },
        { title: 'Personal', content: <PersonalTab memberInfo={memberInfo} setMemberInfo={setMemberInfo} onTabEnable={onTabEnable} onCancel={confirm} /> },
        {
            title: 'Agreement',
            content: <AgreementTab memberInfo={memberInfo} planInfo={planInfo} setPlanInfo={setPlanInfo} onTabEnable={onTabEnable} onCancel={confirm} />,
        },
        { title: 'Payment Amounts', content: <PaymentAmountTab memberInfo={memberInfo} planInfo={planInfo} onTabEnable={onTabEnable} onCancel={confirm} /> },
        {
            title: 'Billing Info',
            content: (
                <BillingInfoTab
                    setLoading={setLoading}
                    memberInfo={memberInfo}
                    loading={loading}
                    onSubmit={onSubmit}
                    payment={payment}
                    setPayment={setPayment}
                    onTabEnable={onTabEnable}
                    onCancel={confirm}
                />
            ),
        },
    ];

    return (
        <>
            <FormPage backText="Plans">
                <CustomTabView tabs={tabs} disabledTabIndices={disabledTabIndices} />
            </FormPage>
        </>
    );
};

export default SellPlanForm;
