import React, { useState } from 'react';
import CustomDialog from '../../shared/Overlays/CustomDialog';
import { CustomGridLayout } from '../../shared/Cards/CustomCard';
import { CustomInput } from '../../shared/Input/AllInputs';
import formValidation from '../../utils/validations';
import { showFormErrors } from '../../utils/commonFunctions';
import { editCalendarEventMember, getCalendarBooking } from '../../redux/actions/Calendar/CalendarAction';
import { useDispatch } from 'react-redux';

const MemberVerification = ({ memberAccessCodeOpen, setMemberAccessCodeOpen, id }) => {
    const [data, setData] = useState({
        accessCode: '',
    });
    const dispatch = useDispatch();
    const handleChange = ({ name, value }) => {
        const formErrors = formValidation(name, value, data);
        setData((prev) => ({ ...prev, [name]: value, formErrors }));
    };
    const onClose = () => {
        setMemberAccessCodeOpen(null);
    };
    const [loading, setLoading] = useState(false);
    const handleSave = () => {
        if (showFormErrors(data, setData)) {
            dispatch(
                editCalendarEventMember(id, setLoading, { memberId: memberAccessCodeOpen, memberAccessCode: data?.accessCode }, () => {
                    setMemberAccessCodeOpen(null);
                    dispatch(getCalendarBooking(id));
                }),
            );
        }
    };
    return (
        <CustomDialog title="Access Code" visible={memberAccessCodeOpen} onCancel={onClose} loading={loading} onSave={handleSave}>
            <CustomGridLayout>
                <CustomInput col="12" name="accessCode" onChange={handleChange} data={data} />
            </CustomGridLayout>
        </CustomDialog>
    );
};

export default MemberVerification;
