// const baseUrl = 'http://localhost:1102/';
const baseUrl = 'https://impactzoneapi.appdeft.in/';
// const baseUrl = 'http://192.168.1.29:1102/';

// const baseUrl = 'https://rnnpg-223-178-208-179.a.free.pinggy.link/';
const endPointUrl = baseUrl + 'api/user';

const constants = { baseUrl, endPointUrl };

export default constants;
