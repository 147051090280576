import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { CustomGridLayout } from '../../shared/Cards/CustomCard';
import CustomTable from '../../shared/Table/CustomTable';
import CustomDialog from '../../shared/Overlays/CustomDialog';
import { useDispatch } from 'react-redux';
import { getCalendarBooking, getEventCategoriesAction, upgradeEventAction } from '../../redux/actions/Calendar/CalendarAction';

const UpgradeDowngradeEvent = ({ isOpen, setIsOpen, event }) => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const [eventsList, setEventsList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selected, setSelected] = useState();

    useEffect(() => {
        getEventCategories();
    }, [event]);

    const getEventCategories = () => {
        dispatch(
            getEventCategoriesAction((res) => {
                let events = res?.eventsInCategory;
                let fillteredEvents = events.filter((item) => item?._id !== event);
                setEventsList(fillteredEvents);
            }),
        );
    };

    const handleSave = () => {
        dispatch(
            upgradeEventAction(id, setLoading, dispatch, { event: selected?._id }, () => {
                setIsOpen(false);
                setSelected();
                dispatch(getCalendarBooking(id));
            }),
        );
    };

    const columns = [
        { selectionMode: 'single', headerStyle: '' },
        {
            field: 'name',
            header: 'Name',
        },
        { field: 'eventType', header: 'Event Type' },
    ];

    return (
        <>
            <CustomDialog
                title={'Upgrade/Downgrade Event'}
                visible={isOpen}
                onCancel={() => {
                    setIsOpen(false);
                }}
                loading={loading}
                onSave={handleSave}
                width="auto"
                savedisabled={!selected}
            >
                <CustomGridLayout>
                    <CustomTable data={eventsList} columns={columns} selectedRow={selected} setSelectedRow={setSelected} />
                </CustomGridLayout>
            </CustomDialog>
        </>
    );
};

export default UpgradeDowngradeEvent;
