import { useState } from 'react';
import api from '../../services/api';
import endPoints from '../../services/endPoints';
import useDebounce from '../useDebounce';
import { useEffect } from 'react';
import { emailValidation, whiteSpaceCheck } from '../../utils/regex';

export default function useValidateEmail(data, setData) {
    const [error, setError] = useState(false);
    const debouncedData = useDebounce(data?.email, 1);

    useEffect(() => {
        const onValidateEmail = async () => {
            if (!debouncedData) {
                setData((prev) => ({
                    ...prev,
                    formErrors: { ...prev.formErrors, email: 'Email is required!' },
                }));
                setError('Email is required!');
                return;
            } else if (whiteSpaceCheck(debouncedData)) {
                setData((prev) => ({
                    ...prev,
                    formErrors: { ...prev.formErrors, email: 'Unnecessary space in word!' },
                }));
                setError('Unnecessary space in word!');
                return;
            } else if (!emailValidation(debouncedData)) {
                setData((prev) => ({
                    ...prev,
                    formErrors: { ...prev.formErrors, email: `Please enter valid email!` },
                }));
                setError(`Please enter valid email!`);
                return;
            }

            const res = await api('post', endPoints.MEMBERS_V2.VALIDATE_EMAIL, {
                email: debouncedData,
                memberId: data._id,
            });

            if (!res.success) {
                setData((prev) => ({
                    ...prev,
                    formErrors: { ...prev.formErrors, email: res.message },
                }));
                setError(res.data);
            } else {
                setError(null);
                setData((prev) => ({
                    ...prev,
                    formErrors: { ...prev.formErrors, email: '' },
                }));
            }
        };
        onValidateEmail();
    }, [debouncedData, data?._id, setData]);
    return { emailError: error };
}
