import { useState } from 'react';
import api from '../../services/api';
import endPoints from '../../services/endPoints';
import useDebounce from '../useDebounce';
import { useEffect } from 'react';

export default function useValidateBarcode(data, setData) {
    const [error, setError] = useState(null);
    const debouncedData = useDebounce(data?.barCode, 1);

    useEffect(() => {
        const onValidatebarCode = async () => {
            if (!debouncedData) {
                setData((prev) => ({
                    ...prev,
                    formErrors: { ...prev.formErrors, barCode: 'Required!' },
                }));
                return;
            }

            const res = await api('post', endPoints.MEMBERS_V2.VALIDATE_BAR_CODE, {
                barCode: debouncedData,
                memberId: data._id,
            });

            if (!res.success) {
                setData((prev) => ({
                    ...prev,
                    formErrors: { ...prev.formErrors, barCode: res.message },
                }));
                setError(res.data);
            } else {
                setError(null);
                setData((prev) => ({
                    ...prev,
                    formErrors: { ...prev.formErrors, barCode: '' },
                }));
            }
        };
        onValidatebarCode();
    }, [debouncedData, data?._id, setData]);
    return { barCodeError: error };
}
