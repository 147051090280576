import { useEffect, useState } from 'react';
import CustomCard, { CustomGridLayout } from '../../../shared/Cards/CustomCard';
import { CustomCalenderInput, CustomDropDown, CustomInput, CustomInputMask, CustomInputNumber } from '../../../shared/Input/AllInputs';
import formValidation from '../../../utils/validations';
import { getCitiesByState, getStatesByCountry, showFormErrors } from '../../../utils/commonFunctions';
import { useDispatch } from 'react-redux';
import { genderOptions } from '../../../utils/dropdownConstants';
import PrimaryButton, { CustomButtonGroup, LightButton } from '../../../shared/Button/CustomButton';
import { useHistory } from 'react-router-dom';
import usePlacesAutocomplete from '../../Members/usePlacesAutoComplete';
import CustomImageInput from '../../../shared/Input/CustomImageInput';

const PersonalTab = ({ onTabEnable, onCancel, memberInfo, setMemberInfo }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    useEffect(() => {
        onTabEnable(1);
    }, []);

    const [states, setStates] = useState([]);
    const [cities, setCities] = useState([]);

    useEffect(() => {
        const _states = getStatesByCountry('US');
        setStates(_states);
    }, [dispatch]);

    useEffect(() => {
        if (memberInfo?.state) {
            const _cities = getCitiesByState('US', memberInfo?.state);
            setCities(_cities);
        }
    }, [memberInfo?.state]);

    const handleChange = ({ name, value }) => {
        const formErrors = formValidation(name, value, memberInfo);
        if (name === 'state') {
            setMemberInfo((prev) => ({ ...prev, [name]: value, city: '', formErrors }));
        }
        setMemberInfo((prev) => ({ ...prev, [name]: value, formErrors }));
    };

    const handleNext = () => {
        if (showFormErrors(memberInfo, setMemberInfo)) {
            history.replace({
                search: `?tab=agreement`,
            });
        }
    };
    const { renderAutocomplete } = usePlacesAutocomplete(memberInfo, setMemberInfo);

    return (
        <>
            <CustomCard col="12" title="Personal">
                <CustomGridLayout>
                    <div className="col-12 grid">
                        <div>
                            <CustomImageInput data={memberInfo} onFilesChange={handleChange} required name="image" editable={true} />
                        </div>
                        <div className="col grid">
                            <CustomInput data={memberInfo} onChange={handleChange} required col={6} name="accessCode" />
                            <CustomInputNumber data={memberInfo} onChange={handleChange} required col={6} name="barCode" />
                            <CustomInput data={memberInfo} onChange={handleChange} required name="firstName" />
                            <CustomInput data={memberInfo} onChange={handleChange} required name="lastName" />
                            <CustomDropDown data={memberInfo} onChange={handleChange} required options={genderOptions} name="gender" />
                        </div>
                    </div>

                    <CustomCalenderInput data={memberInfo} onChange={handleChange} required label="Date Of Birth" name="dob" />
                    <CustomInput data={memberInfo} onChange={handleChange} required name="email" />
                    <CustomInputMask data={memberInfo} onChange={handleChange} required name="primaryPhone" mask="(999) 999-9999" />

                    <div className="col-12">
                        <label className="text-sm font-semibold">Address</label>
                        <span className="text-red-500">*</span>
                        {renderAutocomplete()}
                    </div>
                    <CustomDropDown name="state" options={states} required onChange={handleChange} data={memberInfo} />
                    <CustomDropDown name="city" options={cities} required onChange={handleChange} data={memberInfo} />
                    <CustomInput name="zipCode" label="Postal Code" required onChange={handleChange} data={memberInfo} disabled={!memberInfo.state} />
                </CustomGridLayout>
            </CustomCard>
            <CustomCard col="12" title="Emergency Contact">
                <CustomGridLayout>
                    <CustomInput data={memberInfo} onChange={handleChange} name="emergencyContactName" />
                    <CustomInputMask data={memberInfo} onChange={handleChange} name="emergencyContactNumber" mask="(999) 999-9999" />
                </CustomGridLayout>
            </CustomCard>
            <CustomButtonGroup>
                <PrimaryButton label="Next" className="mx-2" onClick={() => handleNext('')} />
                <LightButton label="Cancel" onClick={onCancel} />
            </CustomButtonGroup>
        </>
    );
};

export default PersonalTab;
