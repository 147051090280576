import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getPaymentMethod } from '../../../redux/actions/MembersPortal/memberPortalActions';
import BillingCard from '../../../shared/Cards/BillingCard';
import CustomCard from '../../../shared/Cards/CustomCard';

const Payment = () => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const paymentMethod = useSelector((state) => state.membersPortal.paymentMethod);

    useEffect(() => {
        if (!paymentMethod?.length) {
            dispatch(getPaymentMethod(id, (data) => {}));
        }
        //eslint-disable-next-line
    }, [id]);

    return (
        <CustomCard col="12" title="Membership Billing">
            <div className="flex flex-wrap gap-3">
                {paymentMethod?.customerPaymentProfiles?.map((item, i) => (
                    <BillingCard key={item?.customerPaymentProfileId} item={item} />
                ))}
            </div>
        </CustomCard>
    );
};

export default Payment;
