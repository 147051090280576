import React, { useEffect, useState } from 'react';
import { BlockUI } from 'primereact/blockui';
import CustomCard, { CustomGridLayout } from '../../../shared/Cards/CustomCard';
import { CustomCheckbox, CustomDropDown } from '../../../shared/Input/AllInputs';
import PrimaryButton, { CustomButtonGroup, LightButton } from '../../../shared/Button/CustomButton';
import { paymentMethodsOptions } from '../../../utils/dropdownConstants';
import CreditCardInput from '../../../shared/Payment/CreditCardInput';
import BillingCard from '../../../shared/Cards/BillingCard';

const BillingInfoTab = ({ setLoading, memberInfo, loading, onTabEnable, onCancel, payment, setPayment, onSubmit }) => {
    useEffect(() => {
        onTabEnable(4);
    }, []);

    const handleChange = ({ name, value }) => {
        setPayment((prev) => ({ ...prev, [name]: value }));
    };

    const { CardInput, handleCreditCardSubmit, validations, handleCreditChange, data } = CreditCardInput();

    const [selectedCard, setSeletedCard] = useState(null);

    const onSelectCard = (id) => {
        setSeletedCard((prev) => {
            if (prev === id) {
                return null;
            } else {
                return id;
            }
        });
    };

    const handleNext = async () => {
        try {
            if (selectedCard) {
                let _paymentObj = {
                    paymentMethod: { customerPaymentProfileId: selectedCard },
                };
                onSubmit(_paymentObj);
            } else {
                setLoading(true);
                let opaqueData = await handleCreditCardSubmit();
                let opaqueDataValidation = await handleCreditCardSubmit();
                let _paymentObj = {
                    paymentMethodType: payment.paymentMethodType,
                    paymentMethod: { opaqueData, opaqueDataValidation, cardHolderName: data.cardHolderName },
                    enableCardOnFile: payment.enableCardOnFile,
                    useClubAccount: payment.useClubAccount,
                };
                onSubmit(_paymentObj);
            }
        } catch (error) {
            setLoading(false);
            alert(error);
        }
    };
    return (
        <>
            <CustomCard col="12" title="Membership Billing">
                <BlockUI blocked={selectedCard}>
                    <CustomGridLayout>
                        <CustomDropDown name="paymentMethodType" data={payment} options={paymentMethodsOptions} onChange={handleChange} />
                        {CardInput({ handleCreditChange, validations, data })}
                        <CustomCheckbox label="Enable Card on File" data={payment} name="enableCardOnFile" onChange={handleChange} col={12} />
                        <CustomCheckbox
                            extraClassName="mb-2"
                            label="Use Payment Option from Club Account"
                            data={payment}
                            name="useClubAccount"
                            onChange={handleChange}
                            col={12}
                        />
                    </CustomGridLayout>
                </BlockUI>
                {memberInfo?.customerPaymentProfiles?.length > 0 && (
                    <CustomGridLayout>
                        <br />
                        <div className="col-12 mb-2">
                            <label className="text-sm font-semibold">Saved Payment Methods</label>
                        </div>
                        <div className="flex flex-wrap gap-3">
                            {memberInfo?.customerPaymentProfiles.map((item, i) => (
                                <BillingCard
                                    key={item.customerPaymentProfileId}
                                    className={`${item.customerPaymentProfileId === selectedCard ? 'selected' : ''}`}
                                    onClick={() => onSelectCard(item.customerPaymentProfileId)}
                                    item={item}
                                />
                            ))}
                        </div>
                    </CustomGridLayout>
                )}
            </CustomCard>
            <CustomButtonGroup>
                <PrimaryButton loading={loading} label="Submit" className="mx-2" onClick={() => handleNext('')} />
                <LightButton label="Cancel" onClick={onCancel} />
            </CustomButtonGroup>
        </>
    );
};

export default BillingInfoTab;
