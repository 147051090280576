import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    deleteEvent,
    getAllCalendarBooking,
    getAllCalendarEvents,
    getCalendarBooking,
    getCalendarEvents,
    getCalendarLocations,
    getCalendarResources,
} from '../../redux/actions/Calendar/CalendarAction';
import { useHistory } from 'react-router-dom';
import { buildEventTitle, confirmDelete, formatEventTime } from '../../utils/commonFunctions';
import BookEvent from './BookEvent';
import CalendarHeader from './CalendarHeader';
import CalendarSideBar from '../../assets/icons/calendarSidebar.png';
import { formatDate, formatDateRange } from './calendarHelper';
import { Calendar } from 'primereact/calendar';
import DefaultCalendarView from './DefaultCalendarView';
import { OverlayPanel } from 'primereact/overlaypanel';
import moment from 'moment';

export default function CalendarComponent() {
    const dispatch = useDispatch();
    const history = useHistory();
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const [calendarView, setCalendarView] = useState('calendar view');

    useEffect(() => {
        dispatch(getCalendarEvents());
        dispatch(getCalendarLocations());
        dispatch(getAllCalendarEvents());
        dispatch(getCalendarResources());
        dispatch(getAllCalendarBooking());
    }, [dispatch]);

    const calendarRef = useRef();

    const { bookedEvents, calendarResourcesDropdown } = useSelector((state) => state.calendar);
    const [selectedResource, setSelectedResource] = useState(null);

    const CalendarEvents = () => {
        const events1 = [];

        const filteredEvents = selectedResource
            ? bookedEvents.filter((item) => {
                  const resourceId =
                      calendarView === 'employee'
                          ? item.staff?._id
                          : calendarView === 'resources'
                            ? item.resources
                            : calendarView === 'location'
                              ? item.location
                              : null;

                  return resourceId === selectedResource;
              })
            : bookedEvents;

        filteredEvents?.forEach((item) => {
            const { start, end } = formatEventTime(item.eventDate, item.startTime, item.duration);

            const eventObj = {
                id: item._id,
                title: buildEventTitle(item.event, item?.staff, item.duration),
                start,
                end,
                textColor: '#fff',
                resourceId: null, // Set below based on view type
            };

            if (item.type === 'BOOKING') {
                eventObj.backgroundColor = '#252b42';
                eventObj.color = '#fff';
                eventObj.resourceId = item.staff?._id;
            } else {
                eventObj.backgroundColor = `#${item?.event?.boxColor}`;
                eventObj.textColor = `#${item?.event?.textColor}`;

                eventObj.resourceId =
                    calendarView === 'employee'
                        ? item.staff?._id
                        : calendarView === 'resources'
                          ? item.resources
                          : calendarView === 'location'
                            ? item.location
                            : null;
            }

            events1.push(eventObj);
        });

        return events1;
    };

    const renderEventContent = (eventInfo) => {
        const title = eventInfo.event.title;

        return (
            <div className="calendar-container" style={{ height: '40px' }}>
                <div
                    style={{
                        fontSize: '10px',
                        fontWeight: '200',
                        whiteSpace: 'normal',
                        wordWrap: 'break-word',
                    }}
                >
                    {title}
                </div>
                <button
                    onClick={(event) => {
                        event.stopPropagation(); // Prevents unwanted bubbling
                        dispatch(getCalendarBooking(eventInfo.event.id)); // Fetch booking details
                        setEventId(eventInfo.event.id); // Set the selected event ID
                        if (overlayRef?.current) {
                            overlayRef?.current?.show(event); // Open the overlay
                        }
                    }}
                    style={{
                        background: 'none',
                        border: 'none',
                        position: 'absolute',
                        right: '5px',
                        cursor: 'pointer',
                        color: 'white',
                        padding: '2px',
                        bottom: 0,
                    }}
                >
                    <i className="pi pi-arrows-alt"></i>
                </button>
            </div>
        );
    };

    const [showDatePicker, setShowDatePicker] = useState(false);
    const [date, setDate] = useState(new Date());
    const [calendarDate, setCalendarDate] = useState(null);
    const initialState = {
        member: '',
        eventDate: null,
        staff: '',
        eventType: '',
        event: '',
        resources: '',
        startTime: '',
        endTime: '',
        duration: '',
        location: '',
    };
    const [data, setData] = useState(initialState);
    const handleDateSelect = (e) => {
        const { value } = e.target;
        if (!(value instanceof Date)) {
            return;
        }
        setShowDatePicker(!showDatePicker);
        setDate(value);
        const calendarApi = calendarRef?.current?.getApi();
        calendarApi.gotoDate(value);
    };

    useEffect(() => {
        const calendarApi = calendarRef?.current?.getApi();
        if (calendarApi) {
            const { type, activeStart, activeEnd } = calendarApi.view;
            if (type === 'resourceTimeGridWeek' || type === 'timeGridWeek') {
                const formattedRange = formatDateRange(activeStart, new Date(activeEnd - 1));
                setCalendarDate(formattedRange);
            } else if (type === 'resourceTimeGridDay' || type === 'timeGridDay') {
                const formattedDate = formatDate(activeStart);
                setCalendarDate(formattedDate);
            }
        }
    }, [showDatePicker]);
    const [eventId, setEventId] = useState(null);

    const overlayRef = useRef(null);

    const handleEventClick = (clickInfo) => {
        history.push(`calender/events/${clickInfo.event.id}`);
    };
    const [view, setView] = useState('day');
    const updateCalendarDate = (view) => {
        if (!view) return;

        const { type, activeStart, activeEnd } = view;
        if (type === 'resourceTimeGridWeek' || type === 'timeGridWeek') {
            const formattedRange = formatDateRange(activeStart, new Date(activeEnd - 1));
            setCalendarDate(formattedRange);
        } else if (type === 'resourceTimeGridDay' || type === 'timeGridDay') {
            const formattedDate = formatDate(activeStart);
            setCalendarDate(formattedDate);
        }
    };
    const { calendarEvent } = useSelector((state) => state.calendar);

    const handleDelete = (position) => {
        confirmDelete(
            () => {
                dispatch(
                    deleteEvent(eventId, () => {
                        dispatch(getAllCalendarBooking());
                    }),
                );
            },
            'Do you want to delete this Event ?',
            position,
        );
    };

    return (
        <>
            <div className="flex justify-content-between ">
                <img
                    src={CalendarSideBar}
                    alt="cal"
                    style={{ width: '65px', height: '65px' }}
                    className="cursor-pointer"
                    onClick={() => setIsSidebarOpen(!isSidebarOpen)}
                />
                <CalendarHeader
                    calendarRef={calendarRef}
                    showDatePicker={showDatePicker}
                    setShowDatePicker={setShowDatePicker}
                    calendarDate={calendarDate}
                    setCalendarDate={setCalendarDate}
                    calendarView={calendarView}
                    setCalendarView={setCalendarView}
                    setSelectedResource={setSelectedResource}
                    selectedResource={selectedResource}
                    view={view}
                    setView={setView}
                    updateCalendarDate={updateCalendarDate}
                />
            </div>
            {showDatePicker && (
                <Calendar onChange={handleDateSelect} value={date} inline className="absolute z-5 w-4" style={{ marginLeft: '500px' }} showLabel={false} />
            )}

            <div className="flex">
                {isSidebarOpen && (
                    <div className="mx-2 sidebar ">
                        <div className="p-">
                            <h3>Member Details</h3>
                            <BookEvent data={data} setData={setData} initialState={initialState} />
                        </div>
                    </div>
                )}

                <div className="w-full relative">
                    <DefaultCalendarView
                        CalendarEvents={CalendarEvents}
                        renderEventContent={renderEventContent}
                        calendarRef={calendarRef}
                        handleEventClick={handleEventClick}
                        calendarView={calendarView}
                        setIsSidebarOpen={setIsSidebarOpen}
                        data={data}
                        setData={setData}
                        selectedResource={selectedResource}
                        setSelectedResource={setSelectedResource}
                        view={view}
                        setView={setView}
                        updateCalendarDate={updateCalendarDate}
                    />
                </div>
            </div>
            {overlayRef ? (
                <OverlayPanel className="p-0 eventshow-panel" ref={overlayRef} dismissable={true} style={{ width: '20rem' }}>
                    <div className="p-0 mx-auto " style={{ height: '8rem' }}>
                        <div style={{ display: 'flex', justifyContent: 'end', alignItems: 'center', marginBottom: '6px', marginTop: '' }}>
                            <div style={{ display: 'flex', gap: '10px' }}>
                                <i className="pi pi-pencil text-blue-400" onClick={() => history.push(`calender/events/${eventId}`)}></i>
                                <i className="pi pi-trash" onClick={handleDelete}></i>
                                <i className="pi pi-times text-red-400" onClick={(e) => overlayRef?.current?.hide(e)}></i>
                            </div>
                        </div>
                        <div className="flex justify-content-between align-items-center">
                            <div
                                className="shadow-1 w-1 h-1rem  "
                                style={{ backgroundColor: `#${calendarEvent?.type === 'CLASS' ? calendarEvent?.event?.boxColor : '252b42'}` }}
                            ></div>
                            <div className="text-sm font-semibold  text-sm   ">{calendarEvent?.event?.name}</div>&nbsp;
                        </div>
                        <div className="flex mt-2 mb-1 align-items-center">
                            <i className="pi pi-calendar mr-5 "></i>
                            <div className="font-semibold text-sm ">{moment(calendarEvent?.eventDate).format('dddd, D MMMM')}</div>
                        </div>
                        <div className="flex mb-1 align-items-center">
                            <i className="pi pi-clock mr-5"></i>
                            <div className="font-semibold text-sm ">{calendarEvent?.duration} minutes</div>
                        </div>
                        <div className="flex mb-1 align-items-center">
                            <i className="pi pi-user mr-5"></i>
                            <div className="font-semibold  text-sm">
                                {calendarEvent?.staff?.firstName}
                                {calendarEvent?.staff?.MI}
                                {calendarEvent?.staff?.lastName}
                            </div>
                        </div>
                    </div>
                </OverlayPanel>
            ) : null}
        </>
    );
}
