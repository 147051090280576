import React, { useState } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';
import resourceTimeGridPlugin from '@fullcalendar/resource-timegrid';
import useEmployees from '../../hooks/Employees/useEmployees';
import { useSelector } from 'react-redux';
import moment from 'moment';

const DefaultCalendarView = ({
    CalendarEvents,
    renderEventContent,
    calendarRef,
    handleEventClick,
    calendarView,
    setIsSidebarOpen,
    data,
    setData,
    selectedResource,
    setSelectedResource,
    view,
    setView,
    updateCalendarDate,
}) => {
    const { employeesDropdown } = useEmployees();
    const { calendarResourcesDropdown, calendarLocationDropdown } = useSelector((state) => state.calendar);
    const handleSelect = (selectionInfo) => {
        console.log(selectionInfo);
        const { start, end } = selectionInfo;
        const startDate = start.getDate();
        const endDate = end.getDate();
        setIsSidebarOpen(true);
        const startTime = moment(selectionInfo.startStr); // Convert to moment object
        const endTime = moment(selectionInfo.endStr); // Convert to moment object

        // Get duration as a Moment.js duration object
        const duration = moment.duration(endTime.diff(startTime));

        console.log(duration.asMinutes(), 'diff');
        setData(() => ({
            member: '',
            eventDate: new Date(selectionInfo.startStr),
            staff: '',
            eventType: '',
            event: '',
            resources: '',
            startTime: selectionInfo.start,
            endTime: selectionInfo.end,
            duration: null,
            location: '',
        }));
    };

    const handleResourceClick = (info) => {
        const calendarAPI = calendarRef?.current?.getApi();
        setSelectedResource(info.resource.id);
        setView('week');
        let newView = calendarView !== 'calendar view' ? 'resourceTimeGridWeek' : 'timeGridWeek';
        calendarAPI.changeView(newView);
        updateCalendarDate(calendarAPI.view);
    };

    const getResources = () => {
        const allResources =
            calendarView === 'employee'
                ? employeesDropdown
                : calendarView === 'resources'
                  ? calendarResourcesDropdown
                  : calendarView === 'location'
                    ? calendarLocationDropdown
                    : [];

        if (selectedResource) {
            return allResources
                .filter((item) => item.value === selectedResource)
                .map((item) => ({
                    id: item.value,
                    title: item.name,
                }));
        }
        return allResources.map((item) => ({
            id: item.value,
            title: item.name,
        }));
    };

    return (
        <FullCalendar
            height="100vh"
            plugins={[resourceTimelinePlugin, dayGridPlugin, timeGridPlugin, interactionPlugin, resourceTimeGridPlugin]}
            headerToolbar={false}
            views={{
                timeGridWeek: {
                    titleFormat: { year: 'numeric', month: 'short', day: '2-digit' },
                },
                timeGridDay: {
                    titleFormat: { year: 'numeric', month: 'short', day: '2-digit' },
                },
                resourceTimeGridWeek: {
                    titleFormat: { year: 'numeric', month: 'short', day: '2-digit' },
                },
                resourceTimeGridDay: {
                    titleFormat: { year: 'numeric', month: 'short', day: '2-digit' },
                },
            }}
            initialView={calendarView === 'calendar view' ? 'timeGridDay' : 'resourceTimeGridDay'}
            selectable={true}
            selectMirror={true}
            dayMaxEvents={true}
            events={CalendarEvents()}
            eventContent={renderEventContent}
            editable={false}
            buttonText={{ today: 'Today', week: 'Week', day: 'Day' }}
            ref={calendarRef}
            slotDuration="00:15:00"
            eventClick={handleEventClick}
            resources={getResources()}
            slotEventOverlap={false}
            eventDisplay="block"
            selectAllow={(selectionInfo) => {
                let startDate = selectionInfo.start;
                let endDate = selectionInfo.end;
                endDate.setSeconds(endDate.getSeconds() - 1);
                if (startDate.getDate() === endDate.getDate()) {
                    return true;
                } else {
                    return false;
                }
            }}
            slotMinWidth={'10px'}
            select={handleSelect}
            resourceLabelDidMount={(info) => {
                info.el.style.cursor = 'pointer';
                info.el.addEventListener('click', () => handleResourceClick(info));
            }}
        />
    );
};

export default DefaultCalendarView;
