import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CustomGridLayout } from '../../shared/Cards/CustomCard';
import { CustomAsyncReactSelect, CustomCalenderInput, CustomDropDown } from '../../shared/Input/AllInputs';
import useEmployees from '../../hooks/Employees/useEmployees';
import useMembers from '../../hooks/Members/useMembers';
import { getDefaultImage, getImageURL } from '../../utils/imageUrl';
import { getMemberData } from '../../redux/actions/MembersPortal/memberPortalActions';
import { EventTypeOptions } from '../../utils/dropdownConstants';
import {
    calendarBooking,
    getAllCalendarBooking,
    getCalendarAvailability,
    getCalendarRecentSessions,
    getEventDetailsAction,
    getStaffDetailsAction,
} from '../../redux/actions/Calendar/CalendarAction';
import formValidation from '../../utils/validations';
import { checkFormErrors, endOfWeek, getDateandTime, getTime, showFormErrors, updateEndTime } from '../../utils/commonFunctions';
import moment, { duration } from 'moment';
import CustomTable from '../../shared/Table/CustomTable';
import CustomDialog from '../../shared/Overlays/CustomDialog';
import { getFormErrors } from '../../redux/actions/helperActions';
import PrimaryButton from '../../shared/Button/CustomButton';
import { showToast } from '../../redux/actions/toastAction';

const BookEvent = ({ data, setData, initialState }) => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const { employeesDropdown } = useEmployees();
    const [openAvailabilty, setOpenAvailability] = useState(false);
    const [openRecentSession, setOpenRecentSession] = useState(false);
    const [isOpenBooking, setIsOpenBooking] = useState(false);
    const [eventDetails, setEventDetails] = useState([]);
    const [staffDetails, setStaffDetails] = useState();

    useEffect(() => {
        if (!data.eventDate) {
            setData((prev) => ({ ...prev, eventDate: new Date() }));
        }
    }, []);

    const { calendarResourcesDropdown, calendarEventsDropdown, calendarEventAppointmentDropDown, calendarEventClassesDropDown, calendarLocationDropdown } =
        useSelector((state) => state.calendar);
    let { events } = useSelector((state) => state.calendar);
    const [eventOptions, setEventOptions] = useState([]);

    useEffect(() => {
        let mergedArray = [];
        if (data?.eventType === 'CLASS') {
            mergedArray = [...calendarEventsDropdown];
        } else if (data?.eventType === 'APPOINTMENT') {
            mergedArray = [...calendarEventAppointmentDropDown];
        } else if (!data?.eventType) {
            if (calendarEventsDropdown.length) {
                mergedArray.push(...calendarEventsDropdown);
            }
            if (calendarEventAppointmentDropDown?.length) {
                mergedArray.push(...calendarEventAppointmentDropDown);
            }
        }
        setEventOptions(mergedArray);
    }, [data, calendarEventsDropdown, calendarEventAppointmentDropDown]);

    const onSubmit = () => {
        if (showFormErrors(data, setData, ['endTime'])) {
            dispatch(
                calendarBooking(
                    {
                        members: [{ member: data.member }],
                        staff: data.staff,
                        eventType: data.eventType,
                        event: data.event,
                        resources: data.resources,
                        eventDate: moment(data.eventDate).format('YYYY-MM-DD'),
                        schedule: [
                            {
                                startTime: getTime(data.startTime),
                                endTime: getTime(data.endTime),
                                duration: data.duration,
                            },
                        ],
                        location: data.location,
                        duration: data.duration,
                    },
                    setLoading,
                    () => {
                        // setOpenBookEvent(false);
                        onClose();
                        dispatch(getAllCalendarBooking());
                    },
                ),
            );
        }
    };

    const handleChange = ({ name, value }) => {
        setData((prev) => {
            const updatedData = { ...prev, [name]: value };
            const formErrors = formValidation(name, value, updatedData);

            if (name === 'duration') {
                updatedData.endTime = updateEndTime(updatedData.startTime, value);
                updatedData.duration = value || null;
            } else if (name === 'startTime') {
                updatedData.endTime = updateEndTime(value, updatedData.duration);
                updatedData.startTime = value || null;
            } else if (name === 'event') {
                updatedData.event = value;
                updatedData.duration = '';
                getEeventDetails(value);
            } else if (name === 'staff') {
                updatedData.staff = value;
                getStaffDetails(value);
            }

            return { ...updatedData, formErrors };
        });
    };

    const onClose = () => {
        // setOpenBookEvent(false);
        setData(initialState);
    };
    const { members } = useMembers();

    const suggestions = useMemo(
        () =>
            members.map((item) => ({
                value: item._id,
                name: `${item.firstName} ${item.MI} ${item.lastName}`,
            })),
        [members],
    );

    const memberOptions = useMemo(() => members?.map((item) => ({ name: `${item.firstName} ${item.MI} ${item.lastName}`, value: item?._id })), [members]);
    useEffect(() => {
        if (data?.member) {
            dispatch(getMemberData(data?.member, 'dashboard'));
        }
    }, [dispatch, data?.member]);

    const memberData = useSelector((state) => state.membersPortal.dashboard);
    events = events?.find((item) => item._id === data?.event);

    // const durationOptions = events?.duration?.map((item) => ({ name: `${item} minutes`, value: item }));

    const { availability, recentSessions } = useSelector((state) => state.calendar);

    console.log(availability, 'availability');

    const columns1 = [
        {
            field: 'createdAt',
            body: (r) => getDateandTime(r?.createdAt),
            header: ' Date',
            style: { width: '1%' },
        },
        { field: 'event', header: 'Event', style: { width: '1%' } },
        { field: 'staff', header: 'Employee', style: { width: '1%' } },
        { field: 'resources', header: 'Resources', style: { width: '1%' } },
    ];
    const columns = [
        {
            field: 'eventDate',
            body: (r) => getDateandTime(r?.eventDate),
            header: ' Date',
        },
        { field: 'event.name', body: (r) => r?.event?.name, header: 'Event' },
        { field: 'staff', header: 'Employee' },
        { field: 'resources', body: (r) => r?.resources[0]?.name, header: 'Resources' },
        {
            field: 'isStaffVerified',
            body: (r) =>
                r?.isStaffVerified ? (
                    <i className="mx-2 cursor-pointer pi pi-check-circle text-green-500"></i>
                ) : (
                    <i className="mx-2 cursor-pointer pi pi-times-circle text-red-500"></i>
                ),

            header: '',
        },
    ];

    const handleRecentSessions = () => {
        const errors = checkFormErrors(data, undefined, true);
        if (errors?.member) {
            setData((ps) => ({ ...ps, formErrors: getFormErrors(errors, ['member']) }));
            return;
        }
        setLoading(true);
        dispatch(
            getCalendarRecentSessions(data?.member, () => {
                setLoading(false);
            }),
        );
        setOpenRecentSession(true);
    };

    const handleAvailability = () => {
        setLoading(true);
        dispatch(
            getCalendarAvailability(
                {
                    eventDate: data.eventDate,
                    ...(data.staff && { staff: data.staff, fromDate: moment(new Date()).format('YYYY-MM-DD'), toDate: moment(endOfWeek).format('YYYY-MM-DD') }),
                },
                () => {
                    setLoading(false);
                },
            ),
        );
        setOpenAvailability(true);
    };

    const getEeventDetails = (event) => {
        dispatch(
            getEventDetailsAction(event, (res) => {
                setEventDetails(res);
            }),
        );
    };

    const getStaffDetails = (event) => {
        dispatch(
            getStaffDetailsAction(event, (res) => {
                setStaffDetails(res);
            }),
        );
    };

    useEffect(() => {
        if (eventDetails && staffDetails) {
            let duration = '';
            if (data?.eventType === 'CLASS') {
                if (staffDetails?.isClassLevel?.length) {
                    staffDetails?.isClassLevel?.map((level) => {
                        const durationTime = eventDetails.find((event) => level === event?.eventLevel);

                        if (durationTime?.duration) {
                            duration = durationTime?.duration;
                        }
                        // else {
                        //     setIsOpenBooking(true);
                        // }
                    });
                } else {
                    dispatch(showToast({ severity: 'error', summary: 'This staff is not assigned to any class level or appointment level.' }));
                }
            } else if (data?.eventType === 'APPOINTMENTS') {
                if (staffDetails.isAppointmentLevel.length) {
                    staffDetails.isAppointmentLevel.map((level) => {
                        const durationTime = eventDetails.find((event) => level === event?.eventLevel);
                        if (durationTime?.duration) {
                            duration = durationTime?.duration;
                        }
                        //  else {
                        //     setIsOpenBooking(true);
                        // }
                    });
                } else {
                    dispatch(showToast({ severity: 'error', summary: 'This staff is not assigned to any class level or appointment level.' }));
                }
            }
            if (!duration) {
                setIsOpenBooking(true);
            }

            setData((prev) => ({ ...prev, ['duration']: duration }));
        }
    }, [staffDetails, eventDetails]);

    return (
        <>
            <CustomGridLayout>
                <CustomAsyncReactSelect
                    name="member"
                    suggestions={suggestions}
                    options={memberOptions}
                    placeholder="Search Member"
                    showLabel={false}
                    value={data.member}
                    onChange={handleChange}
                    col={12}
                />
                {data?.formErrors?.member && (
                    <div className="text-sm p-error" id="error-element">
                        {data?.formErrors?.member}
                    </div>
                )}
                {data?.member && (
                    <div className=" my-2 mx-2 border-round-xl shadow-2  flex justify-content-between p-1 w-full">
                        <div className="flex w-full justify-content-between">
                            <div className="flex gap-5">
                                <div className="avatar-img1">
                                    <img
                                        className="fit-cover rounded-full border-white border-2"
                                        src={getImageURL(memberData?.image)}
                                        onError={(e) => (e.target.src = getDefaultImage())}
                                        alt=""
                                    />
                                </div>

                                <div className="flex flex-column justify-center">
                                    <p className=" text-2xl font-medium ">
                                        {memberData && memberData?.firstName + ' ' + memberData?.MI + ' ' + memberData?.lastName}
                                    </p>
                                    <p className=" font-medium mt-2">Barcode: {memberData && memberData?.barCode}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </CustomGridLayout>
            <CustomGridLayout>
                <CustomCalenderInput name="eventDate" data={data} onChange={handleChange} col={12} minDate={new Date()} />
                <CustomDropDown name="eventType" data={data} options={EventTypeOptions} onChange={handleChange} col={12} />
                <CustomDropDown
                    name="event"
                    data={data}
                    // options={
                    //     data?.eventType === 'CLASS'
                    //         ? calendarEventsDropdown
                    //         : data?.eventType === 'APPOINTMENT'
                    //           ? calendarEventAppointmentDropDown
                    //           : eventOptions
                    // }
                    options={eventOptions}
                    onChange={handleChange}
                    col={12}
                />
                <CustomCalenderInput name="startTime" data={data} onChange={handleChange} col={12} timeOnly hourFormat="12" />
                {/* <CustomDropDown name="duration" options={durationOptions} onChange={handleChange} data={data} col={12} /> */}
                <CustomCalenderInput name="endTime" data={data} onChange={handleChange} col={12} timeOnly hourFormat="12" />
                <CustomDropDown name="staff" options={employeesDropdown} data={data} onChange={handleChange} col={12} />
                <CustomDropDown name="resources" options={calendarResourcesDropdown} data={data} onChange={handleChange} col={12} />
                <CustomDropDown name="location" label="Locations" options={calendarLocationDropdown} data={data} onChange={handleChange} col={12} />
            </CustomGridLayout>
            <div className="flex flex-col items-center space-y-2 w-full">
                <PrimaryButton onClick={onSubmit} className="w-full ml-2 mt-2" size="small" loading={loading} disabled={!data.duration}>
                    Book
                </PrimaryButton>
                <PrimaryButton onClick={handleRecentSessions} className="w-full ml-2 mt-2" size="small">
                    Recent Sessions
                </PrimaryButton>
                <PrimaryButton onClick={handleAvailability} className="w-full ml-2 mt-2" size="small">
                    Available
                </PrimaryButton>
                {/* <button className="w-full text-center ml-2 mt-2" onClick={onSubmit} loading={loading}>
                    Book
                </button> */}
                {/* <button className="w-full text-center ml-2 mt-2" onClick={handleRecentSessions}>
                    Recent Sessions
                </button>
                <button className="w-full text-center ml-2 mt-2" onClick={handleAvailability}>
                    Available
                </button> */}
            </div>
            <CustomDialog title="Availability" visible={openAvailabilty} onCancel={() => setOpenAvailability(false)} width="100vh">
                <CustomTable loading={loading} data={availability?.events} columns={columns} convertToboolean={false} />
            </CustomDialog>
            <CustomDialog title="Recent Sessions" visible={openRecentSession} onCancel={() => setOpenRecentSession(false)} width="100vh">
                <CustomTable loading={loading} data={recentSessions} columns={columns1} convertToboolean={false} />
            </CustomDialog>
            <CustomDialog title="Event Booking" visible={isOpenBooking} onCancel={() => setIsOpenBooking(false)} width="100vh">
                <span>You are not able to book this event.</span>
            </CustomDialog>
        </>
    );
};
export default BookEvent;
